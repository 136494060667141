section.contato {

	> span {
		padding-right: 135px;
	}

	h2 {

		position: relative;
		display: table;
		padding: 23px 60px 0 0;
		font-size: 65px;
		font-weight: bold;
		line-height: 70px;

		&:before {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			width: 38px;
			height: 38px;
			background: #4CE0D5;
			border-radius: 4px;
		}

	}

	em {
		display: block;
		margin-top: 24px;
		font-size: 36px;
		font-style: normal;
		font-weight: 400;
		line-height: 52px;
	}

	a.button {
		margin-top: 24px;
	}

}

@media( max-width: 1023px ){

	section.contato {

		> span {
			padding-right: 64px;
			padding-left: 16px;
		}

		h2 {

			padding: 0;
			font-size: 32px;
			line-height: 32px;

			&:before {
				position: initial;
				right: initial;
				top: initial;
				margin-bottom: 33px;
			}

		}
		em {
			font-size: 20px;
			line-height: 30px;
		}

	}

}