section.image-full {

    &.full-screen-width > span {
        padding-left: 0;
        padding-right: 0;
    }

    img {
        margin: 0 auto;
    }

}