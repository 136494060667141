main.contato section {

    &.desenvolver-com-a-gente {

        position: relative;
        background: linear-gradient(180deg, rgba(76, 224, 213, 0.8) 0%, #4A30AA 100%);
        overflow: hidden;

        > span {
            display: flex;
            flex-direction: column;
            gap: 48px;
        }

        h1 {
            font-size: 60px;
            font-weight: 700;
            line-height: 70px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        a {
            margin: 0;
            display: inline-flex;
            align-items: center;
            font-size: 40px;
            font-weight: 800;
            line-height: 48px;
            letter-spacing: 0.1em;
            text-decoration: none;
            color: #f2f2f2;
            transition: all .4s ease-in-out;

            svg {
                
                margin-left: 16px;

                path {
                    transition: all .4s ease-in-out;
                }

            }

            &:hover {

                color: #4a30aa;

                svg path {
                    stroke: #4a30aa;
                }
            
            }
        
        }

        div {

            margin-left: auto;
            padding-right: 42px;

            strong {
                display: block;
                font-size: 40px;
                font-weight: 900;
                line-height: 60px;
                letter-spacing: 0.1em;
            }

            svg {
                margin-top: 16px;
            }
        
        }

        &:after {
            width: 681px;
            height: 681px;
            top: initial;
            right: calc( 50% + 215px );
            bottom: -352px;
            background-image: url('../images/firula-contato.svg');

            @extend .anima-02;
        
        }

        &.visited:before {
            opacity: 1;
            bottom: -352px;
            right: calc( 50% + 215px );
        }
    
    }

    &.site-footer {
        background-color: #4A30AA;
    }

}

@media( max-width: 1023px ){
    
    main.contato section.desenvolver-com-a-gente {

        a {
            font-size: 32px;
            line-height: 42px;
            letter-spacing: 3.2px;
        }

        h1 {
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 2.2px;
        }

        div {

            strong {
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 2.4px;
            }

            svg {
                width: 296px;
                height: auto;
            }
        
        }

    }

}

@media( min-width: 1024px ) AND ( max-height: 800px ){

    main.contato section.desenvolver-com-a-gente {

        > span {
            gap: 32px;
        }

        h1 {
            font-size: 60px;
            line-height: 70px;
        }

        a {
            font-size: 30px;
            line-height: 32px;
        }

        div {

            strong {
                font-size: 32px;
                line-height: 44px;
            }

            svg {
                width: 100%;
                max-width: 570px;
                height: auto;
            }

        }

        .seta {
            bottom: 20px;
        }

    }

}

@media( max-width: 767px ){

    main.contato section.desenvolver-com-a-gente {

        > span {
            gap: 32px;
        }
    
        div {

            margin-left: 0;

            strong {
                font-weight: bold;
            }
        
        }

        h1 {
            font-size: 32px;
            line-height: 42px;
            letter-spacing: 0.1em;
        }

        a {

            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.1em;
            font-weight: bold;

            svg {
                transform: translateY(-2px);
            }
        
        }

        &:after {
            bottom: 60px;
            right: calc( 50% + 12px );
            width: 308px;
            height: 308px;
        }
    
    }

}
