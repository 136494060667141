main.o-que-fazemos {

    .video-wrapper {
        border: 3px solid #FFF;
        border-radius: 10px;
        overflow: hidden;
    }

    .video-limit {
        display: block;
        width: 100%;
        max-width: 1024px;
        margin: 16px auto;
    }

    > section {

        position: relative;

        h1 {

            letter-spacing: 0.1em;
            font-weight: 900;
            font-size: 48px;
            line-height: 58px;

            strong {
                display: block;
                margin-top: 40px;
                font-size: 160px;
                font-weight: 900;
                line-height: 170px;
            }

        }

        &.o-que-fazemos {

            background: linear-gradient(180deg, #063FB9 0%, #002777 100%);
        
            &:before {

                width: 1056px;
                height: 1056px;
                top: -487px;
                left: calc( 50% - 189px );
                filter: invert(11%) sepia(84%) saturate(5265%) hue-rotate(223deg) brightness(97%) contrast(95%);
                opacity: 0.2;

                @extend .anima-01;

            }

            &:after {

                width: 681px;
                height: 681px;
                top: -5px;
                right: calc( 50% + 159px );

                @extend .anima-02;

            }

        }

        &.excelencia {

            background: #002777;

            &:before {
                position: absolute;
                opacity: 1;
                right: 108px;
                bottom: 0;
                width: 305px;
                height: 305px;
                background-image: url('../images/firula-04.svg');
            }
        
        }

        &.estrategia-e-bi,
        &.usabilidade {
        
            &:before {
                opacity: 1;
                right: 35px;
                width: 469px;
                height: 469px;
                background-image: url('../images/firula-03.svg');
            }

        }
        
        &.estrategia-e-bi {

            background: linear-gradient(180deg, #002777 0%, #063FB9 100%);

            &:before {
                top: 100%;
                transform: translateY(-50%);
            }

        }
        &.usabilidade {

            background: #063FB9;
        
            &:before {
                bottom: 100%;
                transform: translateY(50%);
            }

        }

        &.criacao,
        &.conteudo,
        &.contato {

            &:before {
                opacity: 0.1;
                left: 0;
                width: 420px;
                height: 420px;
                background-image: url('../images/firula-01.svg');
                filter: invert(14%) sepia(72%) saturate(5488%) hue-rotate(223deg) brightness(80%) contrast(95%);
            }

        }
        &.criacao {

            background: linear-gradient(180deg, #063FB9 0%, #002777 100%);
        
            &:before {
                top: 100%;
                transform: translate( -53.5714285714%, -50% );
            }
        
        }
        &.conteudo {

            background: #002777;
        
            &:before {
                bottom: 100%;
                transform: translate( -53.5714285714%, 50% );
            }

        }
        &.contato {

            background: linear-gradient(180deg, #002777 0%, #063FB9 100%);

            &:before {
                left: calc( 50% + 68px );
                top: 52%;
            }
        
        }

        &.site-footer {
            background: #063FB9;
        }

    }

}


@media( max-width: 1023px ){

    main.o-que-fazemos section {

        h1 {

            font-size: 40px;
            line-height: 45px;

            strong {
                margin-top: 20px;
                font-size: 80px;
                line-height: 85px;
            }

        }

    }

}

@media( max-width: 767px ){

    main.o-que-fazemos section {

        h1 {
            
            font-size: 32px;
            line-height: 42px;

            strong {
                margin-top: 16px;
                font-size: 42px;
                line-height: 52px;
            }

        }
       
        &.o-que-fazemos {

            &:before {
                width: 401px;
                height: 401px;
                top: 19px;
                left: calc( 50% - 113px );
            }

            &:after {
                width: 327px;
                height: 327px;
                top: 156px;
                right: calc(50% + 17px);
            }

        }

    }

}