section.relateds {

	h2 {
		margin: 0 0 56px;
		font-size: 60px;
		font-weight: bold;
		line-height: 70px;
	}

	h3, em {

		br {
			display: none;
		}

	}

	h3 {
		margin: 8px 0;
		font-size: 32px;
		font-weight: bold;
		line-height: 32px;
	}

	em {
		
		font-size: 16px;
		font-weight: 300;
		line-height: 18px;
		text-transform: uppercase;

	}

	img {
		border-radius: 10px;
		transition: all .4s ease-in-out;
	}

	a {

		text-decoration: none;
		color: #f2f2f2;

		&:hover img {
			transform: scale(1.05);
		}
	
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		margin: 0;
		list-style: none;
		gap: 56px;
	}

	li {

		width: calc( 50% - 50px );

		a {
			display: flex;
			align-items: center;
			gap: 16px;
		}

		picture {
			width: 150px;
		}

		div {
			flex: 1;
		}
	
	}

}

@media( max-width: 1023px ){

	section.relateds {

		h2 {
			font-size: 32px;
			line-height: 42px;
		}

		h3 {
			font-size: 22px;
			line-height: 24px; 
		}

		em {
			font-size: 12px;
			font-weight: 200;
			line-height: 12px;
			text-trasform: uppercase;
		}

		li {

			width: calc( 50% - 20px );

			picture {
				width: 80px;
			}
		
		}

		ul {
			gap: 40px;
		}

	}
	
}

@media( max-width: 600px ){
	
	section.relateds {

		h2 {
			margin-bottom: 32px;
		}

		ul {
			flex-direction: column;
		}

		li {
			width: 100%;
		}

	}

}