body.page-quem-faz-a-moringa {

    > main > section > span {
        padding-right: 55px;
    }

    section {

        &.talento-e-valorizacao {

            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            background: linear-gradient(180deg, #063FB9 0%, rgba(6, 63, 185, 0.50) 100%);

            h1 {
                
                font-weight: 900;

                strong {
                    color: #F2F2F2;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: 80px;
                    font-weight: 900;
                    line-height: 74px;
                }

                > span {

                    display: block;
                    width: 100%;

                    > span {
                        font-size: 64px;
                        font-style: normal;
                        line-height: 74px;
                        color: transparent;
                        -webkit-text-stroke: 2px #F2F2F2;
                        text-shadow: none;
                    }

                    &:last-child {
                        text-align: right;
                    }
                
                }
            
            }

            ul {

                padding: 0;
                margin: 40px 0 0;
                list-style: none;

                li {

                    margin: 0;
                    color: #F2F2F2;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 42px;

                    &:not(:first-child){
                        margin-top: 16px;
                    }
                
                }

            }

            &:before {

                width: 943px;
                height: 943px;
                top: -341px;
                left: calc( 50% - 156px );
                filter: invert(99%) sepia(5%) saturate(29%) hue-rotate(273deg) brightness(112%) contrast(90%);

                @extend .anima-01;

            }

        }

        &.video {

            padding-top: 120px;

            h2 {
                display: block;
                margin-bottom: 32px;
                text-align: center;
                color: #063FB9;
                font-size: 32px;
                font-weight: 400;
                line-height: 42px;
            }

            .video-wrapper {

                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4646464646%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            
            }

        }

        &.equipe {

            padding-top: 169px;
            padding-bottom: 20px;

            h2 {

                font-size: 164px;
                font-weight: 900;
                line-height: 164px;
                
                span {

                    display: block;

                    &:first-of-type {
                        color: #D9D9D9;
                    }

                    &:last-of-type {
                        color: #070606;
                    }
                
                }
            
            }
            header {

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;

                em {
                    flex-basis: 100%;
                    display: block;
                    margin-top: 67px;
                    color: #063FB9;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: bold;
                    line-height: 48px;
                }
            
            }

            .leaders {

                display: flex;
                margin-top: 96px;

                h3 {
                    margin-top: 8px;
                    font-size: 16px;
                    font-weight: 700;
                }

                picture {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-top: 100%;
                    background: #000;
                    border-radius: 10px;
                    overflow: hidden;
                }

                img {

                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    filter: grayscale(100%);
                    transition: .6s ease-in-out;
                
                }

                > em {
                    display: block;
                    width: 544px;
                    margin-right: -182px;
                    color: #063FB9;
                    font-size: 26px;
                    font-style: normal;
                    line-height: 39px;
                }

                .slick-slider .slick-list {
                    overflow: initial;
                }

                div.slider {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                }

                div.item,
                div.slider:before {
                    width: 183px;    
                }

                div.item {

                    width: 183px;
                    color: #2F2E30;
                    
                    em {
                        display: block;
                        margin-top: 2px;
                        font-size: 12px;
                        text-transform: capitalize;
                        font-weight: 200;
                    }

                    img {
                        width: 100%;
                    }

                    &:hover img {
                        filter: grayscale(0);
                        transform: scale(1.02);
                    }

                }

                &:before {
                    @extend .pseudo;
                    position: absolute;
                    top: 338px;
                    right: calc( 50% + 331px );
                    width: 461px;
                    height: 461px;
                    background: url('../images/firula-quem-faz.svg') no-repeat center center;
                    background-size: contain;
                }

            }

            footer {

                margin-top: 100px;
                text-align: center;
                color: #2F2E30;
                font-size: 32px;
                font-weight: 300;
                line-height: 33px;

                p {

                    margin: 0 auto;
                    max-width: 860px;

                    &:not(:last-child){
                        margin-bottom: 24px;
                    }
                
                }

                em {
                    display: block;
                    margin-bottom: 24px;
                    color: #063FB9;
                    font-size: 100px;
                    font-weight: 800;
                    line-height: 110px;
                }

            }

        }

        &.site-footer {
            padding: 125px 0;
            background: #333;
        }

    }

}

@media( max-width: 1023px ){

    body.page-quem-faz-a-moringa {

        section {

            &.talento-e-valorizacao {

                h1 {

                    max-width: 600px;
                    
                    strong {
                        font-size: 44px;
                        line-height: 40px;
                    }

                    > span > span {
                        font-size: 35px;
                        line-height: 40px;
                    }
                
                }

                ul li {
                    font-size: 22px;
                    line-height: 33px;
                }

            }


            &.equipe {
                
                padding-top: 275px;

                > span {
                    padding-left: 16px;
                    padding-right: 16px;
                }

                h2 {
                    font-size: 64px;
                    line-height: 74px;
                }

                header {

                    em {
                        margin-top: 40px;
                        font-size: 26px;
                        line-height: 40px;
                    }
                
                    img {
                        width: 125px;
                        position: absolute;
                        bottom: 100%;
                        right: 0;
                    }
                
                }
                
                footer {

                    p {

                        font-size: 22px;
                        line-height: 34px;

                        &:not(:last-child) {
                            margin-bottom: 16px;
                        }
                    
                    }

                    em {
                        margin-bottom: 16px;
                        font-size: 40px;
                        line-height: 50px;
                    }
                
                }
                
                .leaders {

                    display: block;

                    > em {
                        width: 100%;
                        margin-right: 0;
                        font-size: 20px;
                        line-height: 33px;
                    }

                    .slider-wrapper {
                        margin-top: 24px;
                    }

                    div.item:first-child {
                        display: none;
                    }

                }

            }

        }

    }

}

@media( min-width: 641px ){

    body.page-quem-faz-a-moringa section.equipe .leaders {

        .slider-wrapper {
            flex: 1;
        }

        div.slider {

            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            &:before {
                content: '';
                display: block;
                height: 10px;
            }
        
        }
    
    }

}

@media( max-width: 767px ){

    body.page-quem-faz-a-moringa section.unidades {
        display: none;
    }

}

@media( max-width: 640px ){

    body.page-quem-faz-a-moringa > main > section.equipe {
    
        .slider-wrapper {
            display: block;
            width: calc( 100% + 12px );
            margin-left: -6px;
            margin-right: -6px;
        }

        div.item {
            width: calc( 33.3333% - 8px );
            padding: 0 6px;
        }
    
    }

}

@media( max-width: 600px ){

    body.page-quem-faz-a-moringa {

        > main > section {

            > span {
                padding-right: 18px;
                padding-left: 18px;
            }

            &.talento-e-valorizacao {

                h1 {

                    strong {
                        display: block;
                        font-size: 40px;
                        line-height: 32px;
                    }

                    > span {

                        > span {
                            font-size: 30px;
                            line-height: 32px;
                        }

                        &:last-child {
                            text-align: left;
                            margin: 16px 0 0 56px;
                        }
                    
                    }
                
                }

                ul {
                    margin-top: 72px;
                }

                &:before {
                    top: -94px;
                    left: calc( 50% - 53px );
                    width: 490px;
                    height: 490px;
                }

            }

        }

    }

}

@media( max-width: 540px ){

    body.page-quem-faz-a-moringa {

         > main > section.equipe {

            div.item {
                width: calc( 50% - 6px );
            }

         }
    
    }

}

@media( max-width: 540px ){

    body.page-quem-faz-a-moringa {

         > main > section {

            &.video {
                padding-top: 42px;
            }

            &.equipe {

                .slider-wrapper {
                    width: 100vw;
                    margin-left: -16px;
                    margin-right: -16px;
                }

                .slick-dots li {
                    width: 24px;
                }

                div.item {
                    display: block !important;
                    width: 100%;
                    max-width: 250px;
                    margin: 0 auto;
                }

            }

         }
    
    }

}
