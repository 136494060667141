section.splash {

    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100dvw;
    height: 100dvh;
    background: rgba( 29, 28, 84, 0.5 );
    z-index: 999;
    opacity: 0;
    transform: translateX(-101dvw);
    transition: 
        opacity .5s ease-in-out,
        transform 1ms ease .5s
    ; 

    .scroller {
        display: block;
        width: 100%;
        max-height: 100dvh;
        padding: 0 24px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: 1148px;
        height: 669px;
        padding: 48px 72px;
        margin: 20px auto;
        background: #4A30AA url('../images/splash-bg.png') no-repeat;
        background-size: auto 100%;
        background-position: right top;
        border-radius: 15px;
        overflow: hidden;
    }

    .graph {

        position: relative;
        display: block;
        
        span {
            
            display: block;
            position: relative;
            width: 100%;
            height: 0;
            overflow: hidden;
            text-indent: -999dvw;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat !important;
                background-position: center center;
                background-size: contain;
            }
        
        }
    
    }

    img {
        width: 103px;
    }
    
    h2,
    p {
        color: #FFF;
    }
    h2 {

        width: 422px;
        margin-top: -88px;

        span {

            padding-top: 86.3582443654%;

            &:after {
                background-image: url('../images/splash-title.png');
            }
        
        }
    
    }
    p {
        max-width: 620px;
        margin: 24px 0 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }
    a.button {

        position: relative;
        margin-top: 32px;
        z-index: 10;
    
        span {
            gap: 16px;
            height: 48px;
            padding: 0 32px;
            background: #4CE0D5;
            border-radius: 24px;
            border: 0;
            color: #070606;
        }
        strong {
            display: block;
            font-weight: normal;
            padding-top: 5px;
            line-height: initial;
        }
        svg {
            margin: 0;
            transition: transform .4s ease-in-out;
        }

        &:hover {
        
            span {
                background-color: #FF00BF;
            }
            svg {
                transform: translateX(5px);
            }
        
        }
    
    }
    button.close {
        
        position: absolute;
        top: 46px;
        right: 24px;
        width: 36px;
        height: 36px;
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer;
        z-index: 999;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 44px;
            height: 4px;
            //background-color: #FFF;
            background-color: #4A30AA;
            transition: all .4s ease-in-out;
        }
        &:before {
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }

        &:hover {

            &:before,
            &:after {
                background-color: #FF00BF;
            }
            &:before {
                transform: translate(-50%,-50%) rotate(405deg);
            }
            &:after {
                transform: translate(-50%,-50%) rotate(-405deg);
            }
        
        }


    }

    .person {

        position: absolute;
        right: 0;
        bottom: 0;
        width: 619px;
        z-index: 1;

        span {

            padding-top: 104.3618739903%;

            &:after {
                background-image: url('../images/splash-pessoa.png');
            }
        
        }
    
    }

    &.showing {
        transition: opacity .3s ease-in-out; 
        opacity: 1;
        transform: translateX(0);
    }

}

@media( max-width: 1170px ){

    section.splash {

        .inner {
            padding: 48px;
        }

        p {
            max-width: 428px;
            font-size: 16px;
            line-height: 24px;
        }

        .person {
            width: 510px;
        }
    
    }

}

@media( max-width: 1070px ){

    section.splash {

        .inner {
            height: 609px;
        }

        h2 {
            width: 300px;
            margin-top: -50px;
        }
        p {
            max-width: 350px;
        }
    
    }

}

@media( max-width: 920px ){

    section.splash {

        .inner {
            height: 560px;
            padding: 24px;
        }

        .person {
            width: 423px;
        }
    
    }

}

@media( max-width: 810px ){

    section.splash .person {
        width: 366px;
    }

}

@media( max-width: 767px ){

    section.splash {

        .inner {
            height: 458px;
        }

        img {
            width: 79px;
        }

        h2 {

            max-width: 312px;
            margin-top: -33px;

            span {

                padding-top: 54.4871794872%;
            
                &:after {
                    background-image: url('../images/splash-title-mobile.png' );
                }

            }
        
        }

        .person {
            width: 300px;
            right: -33px;
        }
    
    }

}


@media( max-width: 670px ){

    section.splash {

        .scroller {
            padding: 0;
        }

        .inner {
            height: 100dvh;
            margin: 0;
            border-radius: 0;
            background-image: url('../images/splash-firula-mob.svg');
        }

        .person {
            width: 324px;
        }
    
    }

}

@media( max-width: 480px ){

    section.splash {

        p {
            flex: 1;
            max-width: 296px;
            margin-top: 12px;
            font-size: 16px;
            line-height: 24px;
        }

        a.button {
            &,
            span {
                width: 100%;
                justify-content: center;
            }
        }

        button.close {

            top: 28px;
            right: 24px;
            width: 32px;
            height: 32px;

            &:before,
            &:after {
                width: 32px;
                height: 3px;
                background-color: 063FB9;
            }
        
        }

        .person {

            right: initial;
            left: 0;

            span {

                padding-top: 119.9074074074%;

                &:after {
                    background-image: url('../images/splash-pessoa-mob.png');
                }

            }

        }
    
    }

}

@media ( max-width: 480px ) AND ( min-max: 738px ){

    .person {
        bottom: initial;
        top: 372px;
    }

}