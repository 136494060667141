main.pagina-nao-encontrada {

	section {

		&:first-of-type {

			padding-top: 64px;
			background: #E9F5FE;

			> span {

				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;
				height: 100%;

				> span > svg {
					display: block;
					width: 100%;
					max-width: 975px;
					height: auto;
					margin: 0 auto;
					transition: all .4s ease-in-out;
				}
			
			}

			em {
				margin-bottom: 66px;
				text-align: center;
				font-size: 64px;
				font-weight: 900;
				line-height: 87px;
				color: #000000;
			}
		
		}

		&.site-footer {
			background: darken( #B7DFFF, 20% );
		}

	}

}

@media( max-width: 1280px ){

	main.pagina-nao-encontrada section:first-of-type > span > span > svg {
		max-width: 76.171875%;
	}

}

@media( max-width: 768px ){
	
	main.pagina-nao-encontrada section:first-of-type {

		em {
		  	margin-bottom: 29px;
		}
		
		> span {

			justify-content: space-between;
			padding-right: 30px;
			padding-bottom: 31px;
			padding-left: 30px;

			> span > svg {
				max-width: 300px;
			}

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
			}

		}

	}

}