main.a-moringa {

    section {

        h1 {

            letter-spacing: 0.1em;
            font-size: 80px;
            font-weight: 800;
            line-height: 90px;

            strong {
                display: block;
                margin-top: 40px;
                font-size: 112px;
                font-weight: 900;
                line-height: 126px;
            }

        }

        &.a-moringa,
        &.pilares,
        &.boas-vindas {
            background: linear-gradient(180deg, #4CE0D5 0%, #063FB9 100%);
        }
        &.tecnologia,
        &.evolucao {
            background: linear-gradient(180deg, #063FB9 0%, #4CE0D5 100%);
        }

        &.tecnologia,
        &.pilares,
        &.evolucao {

            &.m-left > span > svg path.dot {
                fill: #063FB9;
            }
        
        }

        &.a-moringa:before {

            width: 943px;
            height: 943px;
            top: -341px;
            left: calc( 50% - 156px );
            filter: invert(99%) sepia(5%) saturate(29%) hue-rotate(273deg) brightness(112%) contrast(90%);

            @extend .anima-01;

        }

        &.boas-vindas {

            em {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 66px;
                font-size: 40px;
                font-weight: normal;
                line-height: 50px;

                svg {
                    width: 270px;
                }
            }

            a.button {
                margin-top: 53px;
            }

        }

        &.projetos {
            background: linear-gradient(180deg, #063FB9 0%, #010E29 100%);
        }

        &.site-footer {
            background-color: #010E29;
        }

    }

}

@media( max-width: 1150px ){

    main.a-moringa section.boas-vindas {

        em {

            flex-direction: column;
            padding-right: 0;

            svg {
                max-width: 100%;
                height: auto;
                margin-top: 16px;
            }
        
        }

        a.button {
            margin: 44px auto;
        }

    }

}

@media( max-width: 1023px ){

    main.a-moringa section h1 {

        font-size: 40px;
        line-height: 45px;
        
        strong {
            margin-top: 24px;
            font-size: 90px;
            line-height: 100px;
        }
    
    }

}

@media( max-width: 768px ){

    body.mobile-nav > main.a-moringa > section {

        &:not(:first-of-type){
            min-height: 0;
        }

        &:nth-child(2){
            padding-top: 94px;
            padding-bottom: 61px;
        }

        &:nth-child(3){
            padding-top: 44px;
            padding-bottom: 44px;
        }
        &:nth-child(4){
            padding-top: 37px;
            padding-bottom: 44px;
        }
        &:nth-child(5){
            padding-top: 72px;
            padding-bottom: 72px;
        }

        &.site-footer {
            background: #010E29;
        }
    
    }

}

@media( max-width: 767px ){

    main.a-moringa section {

        > span {
            padding-left: 18px;
            padding-right: 18px;
        }

        h1 {

            font-size: 50px;
            line-height: 60px;
            letter-spacing: 3.2px;
            font-weight: bold;

            strong {
                margin-top: 45px;
                font-size: 50px;
                line-height: 55px;
                font-weight: 800;
                letter-spacing: 4.2px;
            }

        }

        &.a-moringa {

            position: relative;

            > span {
                padding-right: 0;
            }

            &:before {
                width: 400px;
                height: 400px;
                top: calc( 50% - 96px );
                left: calc( 50% - 76px );
            }
        
        }
    
    }

}

@media( max-width: 600px ){

    main.a-moringa section.boas-vindas em {
        font-size: 18px;
        line-height: 34px;
    }

}