section.brand {

	h2 {
		font-size: 80px;
		font-weight: 800;
		line-height: 90px;
		color: transparent;
		-webkit-text-stroke: 2px #f2f2f2;
		text-stroke: 2px #f2f2f2;
		text-shadow: none;
	}

	img {
		border-radius: 10px;
		margin: 0 auto;
	}

	.video-wrapper {
		display: block;
		max-width: 888px;
		margin: 0 auto;
	}

}

@media( max-width: 768px ){

	section.brand {

		h2 {
			font-size: 60px;
			line-height: 70px;
		}

	}
	
}

@media( max-width: 540px ){

	section.brand {

		h2 {
			margin-bottom: 16px;
			font-size: 40px;
			line-height: 50px;
			text-align: center;
		}

	}
	
}
