main.projetos {

    img {
        border-radius: 10px;
    }

    .projeto {

        width: 44.4444444444%;
        color: #F2F2F2;
        text-decoration: none;

        h3 {
            margin: 8px 0;
            font-size: 52px;
            font-weight: 800;
            line-height: 50px;
        }

        em {
            text-transform: uppercase;
            font-weight: 200;
        }

        picture {

            display: block;
            position: relative;
            height: 0;
            padding-top: 125.706940874%;
            border-radius: 10px;
            overflow: hidden;
            background: #4A30AA;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #2F2E30;
                opacity: 0.5;
                z-index: 5;
            }

        }

        .image-wrapper {
            display: block;
            padding-right: 14.2857142857%;
        }

        figure {

            position: relative;
            display: block;
            width: 100%;
            transition: all .6s ease-in-out;

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 94.9230769231%;
                height: 95.2380952381%;
                border-radius: 10px;
                background: #B52BBB;
                z-index: 1;
                transition: all .6s ease-in-out;
            }
        
        }

        > div:nth-child(2){
            position: absolute;
            right: 0;
            bottom: 24px;
            transition: all .6s ease-in-out;
        }

        &:nth-child(odd) {

            > div:nth-child(2){
                bottom: 50px;
            }

            figure {

                padding: 0 0 26px 28px;

                &:after {
                    left: 0;
                    bottom: 0;
                }
            
            }
            
            &:hover {

                > div:nth-child(2){
                    bottom: 24px;
                }

                figure {
                        
                    padding: 26px 28px 0 0;

                    &:after {
                        bottom: 26px;
                        left: 28px;
                    }

                }

            }

        }

        &:nth-child(even) {

            figure {

                padding: 26px 28px 0 0;

                &:after {
                    top: 0;
                    right: 0;
                }

            }

            &:hover {

                > div:nth-child(2){
                    bottom: 50px;
                }

                figure {
                        
                    padding:  0 0 26px 28px;

                    &:after {
                        top: 26px;
                        right: 28px;
                    }

                }

            }

        }

    }

    section {

        background: linear-gradient(178.78deg, rgba(74, 48, 170, 0.4) 1.03%, #4A30AA 98.95%);

        &:nth-child(even){
            background: linear-gradient(180deg, #4A30AA 0%, rgba(74, 48, 170, 0.4) 100%);
        }

        &.hero-projetos {
            
            &:before {

                width: 1056px;
                height: 1056px;
                top: -409px;
                left: calc( 50% - 200px );
                filter: invert(19%) sepia(97%) saturate(1896%) hue-rotate(242deg) brightness(84%) contrast(99%);
                mix-blend-mode: screen;

                @extend .anima-01;

            }

            h1 {

                display: flex;
                flex-direction: column;
                gap: 50px;

                span {
                    
                    font-size: 180px;
                    font-weight: 900;
                    line-height: 130px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    -webkit-text-stroke: 2px #FFF;
                    text-stroke: 2px #FFF;
                    text-shadow: none;
                
                    &:first-child {
                        color: transparent;
                    }
                
                }

            }

            h2 {
                margin: 0;
                font-size: 50px;
                font-weight: 700;
                line-height: 60px;
            }

            p {
                margin: 16px 0 0;
                font-size: 32px;
                line-height: 42px;
            }

            div {
                padding-bottom: 8px;
            }

            > span {
                display: flex;
                align-items: flex-end;
                gap: 95px;
            }

        }

        &.linha-projetos > span {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
        }

        &.site-footer {
            background-color: #4A30AA;
        }

    }

}

@media( max-width: 1023px ){

    main.projetos {

        section {

            background: linear-gradient(180deg, #4A30AA 0%, rgba(74, 48, 170, 0.4) 100%);

            &:nth-child(even){
                background: linear-gradient(178.78deg, rgba(74, 48, 170, 0.4) 1.03%, #4A30AA 98.95%);
            }

            &.hero-projetos {

                > span {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 18px;
                }

                h1 {

                    gap: 20px;

                    span {
                        font-size: 80px;
                        line-height: 58px;
                    }

                }

                h2 {
                    font-size: 22px;
                    line-height: 33px;
                }

                p {
                    margin: 8px 0 0;
                    font-size: 22px;
                    line-height: 33px
                }

                &:before {
                    top: -207px;
                    left: calc(50% - 67px);
                    width: 631px;
                    height: 631px;
                }

            }

        }
    
    }

}

@media( max-width: 865px ){
    
    main.projetos .projeto {

        h3 {
            font-size: 32px;
            line-height: 42px;
        }
        
        em {
            font-size: 16px;
            font-weight: 400;
        }
    
    }

}

@media( max-width: 640px ){
    
    main.projetos section.carousel-projetos {

        > span {
            padding-right: 18px;
        }

        .carousel-wrapper {
            display: block;
            width: 100vw;
            margin: 0 -18px;
        }

        .slick-dots li {

            width: 16px;

            button {
                content: '';
                color: transparent;
            }
        
        }

        .projeto {

            display: block !important;
            max-width: 320px;
            margin: 0 auto;

            h3 {
                
                margin: 4px 0 8px;
            
                br {
                    display: none;
                }
            
            }

            .image-wrapper {
                padding-right: 0;
            }
            
            picture {
                padding-top: 125.1633986928%;
            }

            > div:nth-child(2){

                position: relative;
                right: initial;
                bottom: initial;
                margin-top: 16px;
                text-align: center;

            }
            
            &:nth-child(odd),
            &:nth-child(even) {

                figure {
                    width: auto;
                    padding: 0 0 20px 22px;
                }

                &:hover figure {
                        
                    padding: 0 0 20px 22px;

                    &:after {
                        bottom: 0;
                        left: 0;
                    }

                }
            
            }

            &:hover > div {
                bottom: initial !important;
            }
        
        }

    }

}

@media( min-width: 1024px ) AND ( max-height: 800px ){

    main.projetos section.hero-projetos {

        > span {
            gap: 48px;
        }

        h1 span {
            font-size: 130px;
            line-height: 80px;
        }

    }

}
