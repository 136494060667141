// Structure
@import 'structure/normalize';
@import 'structure/vars';
@import 'structure/animations';
@import 'structure/fonts';
@import 'structure/base';
@import 'structure/blocked-page';
@import 'structure/snaped-page';
@import 'structure/menu-toggle';
@import 'structure/header';
@import 'structure/footer';

// Components
@import 'components/button';
@import 'components/slick';
@import 'components/splash';
@import 'components/menu-nav-side';
@import 'components/modal-video';
@import 'components/projetos';
@import 'components/contato';
@import 'components/unidades';
@import 'components/just-text';
@import 'components/text-image-side';
@import 'components/m-de-moringa';
@import 'components/strong-bottom';
@import 'components/image-blockquote';
@import 'components/big-numbers';
@import 'components/brand';
@import 'components/image-full';
@import 'components/relateds';

// Pages
@import 'pages/a-moringa';
@import 'pages/o-que-fazemos';
@import 'pages/quem-faz-a-moringa';
@import 'pages/projetos';
@import 'pages/single-projetos';
@import 'pages/contato';
@import 'pages/pagina-nao-encontrada';

// Internet explorer
@import 'structure/ie';

@media( min-width: 480px ){
    body .just-mobile {
        display: none !important;
    }
}
@media( max-width: 481px ){
    body .hide-mobile {
        display: none !important;
    }
}