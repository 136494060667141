section.unidades {

	padding-top: 0;
	margin-top: 56px;
	background-color: #333333;

	> span {
		background: url('../images/mapa.png') no-repeat center center;
	}

	.lista-unidades {

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 36px;
		padding-top: 157px;
		padding-bottom: 157px;
		margin: 0 auto;
		text-align: center;
		list-style: none;
		transition: all .8s ease;

		&.hover {
			padding-top: 114px;
			padding-bottom: 157px;
		}
	
	}

	.unidade {

		position: relative;

		div {
			position: absolute;
			top: 50%;
			width: 230px;
			transform: translateY( calc( -50% - 43px ) );
			z-index: 1;
		}

		picture,
		button,
		ul {
			transition: all .8s ease;
		}

		img {
			border-radius: 5px;
		}

		button {
			position: relative;
			padding: 10px 0;
			color: #BDBDBD;
			font-size: 100px;
			font-weight: 800;
			line-height: 100px;
			background: none;
			border: 0;
			z-index: 10;
			
		}
		picture {
			opacity: 0;
		}

		ul {
			padding: 0;
			margin: 9px 0 0;
			list-style: none;
			opacity: 0;
		}

		li {
			color: #F2F2F2;
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
			text-align: left;
		}

		&:nth-child(odd){

			div {
				left: calc( 100% + 10px );
			}
			ul {
				transform: translate( -400px, 20px );
			}
			picture {
				transform: scale(0.7) translate(-100%, -40%);
			}
		
		}

		&:nth-child(even){

			div {
				right: calc( 100% + 10px );
			}
			ul {
				transform: translate(400px, 20px);
			}
			picture {
				transform: scale(0.7) translate(100%, -40%);
			}

		}

		&.hover:nth-child(odd).hover,
		&:nth-child(even).hover {

			button {
				color: #FF00BF;
				margin-bottom: 43px;
			}
			ul,
			picture {
				opacity: 1;
			}
			picture {
				transform: scale(1);
			}
			ul {
				transform: translate(0,0);
			}
		
		}

	}

}