section.image-blockquote {

	picture {
		margin-bottom: 16px;
	}

	img {
		border-radius: 10px;
		margin: 0 auto;
	}

	blockquote {
		margin: 0;
		font-size: 32px;
		font-style: italic;
		line-height: 48px;
	}

}

@media( max-width: 767px ){
	section.image-blockquote blockquote {
		font-size: 22px;
		line-height: 33px;
	}
}