body.single-projetos {

    img {
        max-height: calc( 100vh - 64px - 80px ) !important;
    }

    .blocked-page-menu.in-first-section {
        top: calc( 50% + 14vh );
    }

    > main > section {

        position: relative;
        background-repeat: no-repeat;
        background-size: 100vw calc( 100vh + 1px );

        > span > .arrow-left {
            margin-bottom: 13px;
            transition: all .3s ease-in-out;
        }

        h1 {
            font-size: 96px;
            font-weight: 700;
            line-height: 106px;
        }

        &:not(.site-footer) p {

            margin: 0;
            font-size: 32px;
            line-height: 48px;
            font-weight: 300;

            &:not(:first-child){
                margin-top: 24px;
            }

        }

        &:nth-child(odd){
            background: linear-gradient(180deg, rgba(74, 48, 170, 0.7) 0%, #002777 100%);
        }
        &:nth-child(even){
            background: linear-gradient(180deg, #002777 0%, rgba(74, 48, 170, 0.7) 100%);
        }

        &.hero {

            picture {
                flex: 1;
            }

            img {
                border-radius: 10px;
            }
            
            em {
                font-size: 24px;
                font-weight: 200;
                line-height: 29px;
                text-transform: uppercase;
            }

            > span > em {
                display: none;
            }
            
            ul {

                display: flex;
                flex-direction: column;
                list-style: none;
                padding: 0;
                margin: 0;
                gap: 16px;

                > strong {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: normal;
                }

                li {
                    text-transnform: uppercase;
                    font-size: 22px;
                    line-height: 33px;
                    font-weight: 200;
                    text-transform: uppercase;
                }
            
            }

            > span {

                padding-right: 0;

                > div {

                    display: flex;
                    margin-top: 5px;

                    > div {
                        width: 369px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                }
            
            }

            .seta {
                bottom: 15px;
            }

            &.style--2 {

                > span > div > div {
                    justify-content: flex-end;
                }

            }

            &:before {

                width: 1056px;
                height: 1056px;
                top: -487px;
                left: calc( 50% - 189px );
                filter: invert(11%) sepia(84%) saturate(5265%) hue-rotate(223deg) brightness(97%) contrast(95%);
                opacity: 0.2;

                @extend .anima-01;

            }

            &:after {

                width: 681px;
                height: 681px;
                top: -70px;
                right: calc( 50% + 159px );

                @extend .anima-02;

            }

        }

        &.site-footer {
            background: rgba(74, 48, 170, 0.7);
        }

        &:not(:first-child):after {
            position: absolute;
            left: calc( 50% + 172px );
            bottom: 0;
            width: 305px;
            height: 305px;
            background-image: url('../images/firula-06.svg')
        }

    }

    &.feitos-de-valores,
    &.o-peso-do-seu-carro,
    &.app-amamenta,
    &.app-sebrae,
    &.canal-empreender,
    &.indicacoes-geograficas,
    &.transamerica-hotels {

        > main > section.hero > span  {

            > em {
                display:  block;
                height: 101px;
            }

            > div > div > em {
                display: none;
            }

        }

    }

}

@media( min-width: 1024px ) AND ( max-height: 800px ){

    body.single-projetos > main > section {

        h1 {
            font-size: 60px;
            line-height: 60px;
        }

        &.hero {
            
            em {
                font-size: 20px;
                line-height: 24px;
            }

            img {
                max-height: 350px !important;
                margin-left: auto;
            }

            ul li {
                font-size: 18px;
                line-height: 24px;
            }

        }

        &:not(.site-footer) p {
            font-size: 28px;
            line-height: 40px;
        }
    
    }

}

@media( max-width: 1279px ){

    body.single-projetos > main > section {

        h1 {
            font-size: 66px;
            line-height: 66px;
        }

        &:not(.site-footer) p {

            font-size: 20px;
            line-height: 30px;
            font-weight: 200;
            
            &:not(:first-child) {
                margin-top: 16px;
            }
        
        }

    }

}

@media( max-width: 768px ){

    body.single-projetos {

        > main > section {

            h1 {

                font-size: 36px;
                line-height: 50px;
                
                br {
                    display: none;
                }
            
            }

            &.hero {

                > span > div > div {
                    width: 260px;
                }

                em {
                    font-size: 16px;
                    line-height: normal;
                }

                ul {

                    gap: 4px;
                
                    li {
                        font-size: 16px;
                        line-height: 24px;
                        text-transform: uppercase;
                    }

                }
            
            }

        }

        .blocked-page-menu.in-first-section {
            top: 50%;
            transform: translateY( calc( -50% + 22px ) );
        }

    }

}

@media( max-width: 540px ){
    
    body.single-projetos > main > section.hero {

        padding-top: 84px;

        > span {

            padding-right: 16px;
            
            > div {

                flex-direction: column-reverse;
                margin-top: 16px;

                 > div > em {
                    display: none !important;
                 }
            
            }

            > em {

                height: auto !important;
                display: block !important;

                br {
                    display: none;
                }
            
            }
        
        }

        img {
            width: 100%;
        }

        em {
            margin-top: 8px;
            text-transform: uppercase;
        }

        ul {

            margin-top: 16px;

            > strong {
                font-size: 12px;
            }
        
        }
    
    }

}
