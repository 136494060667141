html.snaped-page-nav {

    &,
    body {
        overflow: hidden;
    }

    body > main {

        display: block;
        width: 100vw;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        /*
        scroll-snap-points-y: repeat(30vh);
        scroll-snap-type: y proximity;
        > section {
            scroll-snap-align: start;
        }
        */

    }

}
.snaped-page-menu {
    @extend .menu-nav-side;
}