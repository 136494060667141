html.blocked-page-nav  {

    &,
    body {   
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    body,
    body > main {
        display: block;
        position: static;
        padding: 0;
        width: 100vw;
        overflow-anchor: none;
    }
    body > main {

        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;

        > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100vw;
            height: calc( 100vh + 1px );
            //height: calc(var(--vh, 1vh) * 100);
            margin-top: -1px;
            overflow: hidden;
            -webkit-perspective: 1000;
            -webkit-backface-visibility: hidden;
        }

    }

}

.blocked-page-menu {
    @extend .menu-nav-side;
}
