section.big-numbers {

	h2 {
		font-size: 32px;
		font-weight: 800;
		line-height: 42px;
		margin-bottom: 56px;
	}

	strong {
		font-size: 22px;
		font-weight: 200;
		line-height: 33px;
		text-transform: uppercase;
	}
	
	em {
		display: block;
		margin-top: 16px;
		font-size: 80px;
		font-weight: 800;
		line-height: 90px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0;
		margin: 0;
		list-style: none;
	}

	li {

		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;
		margin-top: 56px; 
		text-align: center;

		&:nth-child(1),
		&:nth-child(2){
			margin-top: 0;
		}
	
	}

	&.with-image {

		> span {
			display: flex;
			align-items: center;
			gap: 67px;
		}

		ul {
		
			flex-direction: column;
			gap: 56px;
			width: 280px;

			li {
				width: 100%;
				margin-top: 0;
			}
		
		}

		picture {

			flex: 1;
			padding: 0 32px;

			img {
				width: 100%;
			}
		
		}

		&.image-big picture {
			padding: 0;
		}

	}

	&.vatical-space--100 li {
		
		&:not(:nth-child(1)):not(:nth-child(2)) {
			margin-top: 100px;
		}
	
	}

}

@media( max-width: 1079px ){

	section.big-numbers.with-image {

		> span {
			gap: 42px;
		}

		picture {
  			padding: 0;
  		}
	
		h2 {
			margin-bottom: 32px;
			font-size: 20px;
			line-height: 30px;
			font-weight: bold;
		}

		em {
			font-size: 56px;
			line-height: 61px;
		}

		li {
			margin-top: 36px;
		}

		ul {
			gap: 22px;
		}

	}

}

@media( max-width: 1023px ){

	section.big-numbers {
	
		h2 {
			margin-bottom: 32px;
			font-size: 20px;
			line-height: 30px;
			font-weight: bold;
		}

		em {
			font-size: 56px;
			line-height: 61px;
		}

		li {
			margin-top: 36px;
		}
	
	}

}

@media( max-width: 767px ){

	section.big-numbers {

		h2 {
			margin-bottom: 24px;
		}
	
		em {
			font-size: 32px;
			line-height: 32px;
		}

		li {
			margin-top: 16px;
		}

		&.vatical-space--100 li:not(:nth-child(1)):not(:nth-child(2)) {
			margin-top: 42px;
		}

		&.with-image {

			> span {
				flex-direction: column;
			}

		}
	
	}

}

@media( max-width: 640px ){

	section.big-numbers {

		em {
			margin-top: 4px;
		}

		li {
			width: 100%;
			margin-top: 0;
		}

		ul {
			flex-direction: column;
			align-items: center;
			gap: 20px;
		}
	
		&.vatical-space--100 li:not(:nth-child(1)):not(:nth-child(2)) {
			margin-top: 0;
		}

	}

}