@keyframes progress {
    from { right: calc( 100% - 1px ); }
    to { right: 0; }
}

.anima-01,
.anima-02 {

    opacity: 0;
    transition: all 1s ease-in-out;

    &.visited {
        transform: scale(1) translate(0,0) rotate(0);
    }

}
.anima-01 {

    transform: scale(1.1) translate(50%,-50%) rotate(90deg);

    &.visited {
        opacity: 0.2;
    }

}
.anima-02 {

    transform: scale(1.1) translate(-50%,-50%) rotate(-90deg);

    &.visited {
        opacity: 1;
    }

}

@keyframes slide {
    from {
        opacity: 0;
        transform: scale(1.1) translate(50%,-50%) rotate(90deg);
        filter: invert(99%) sepia(5%) saturate(29%) hue-rotate(273deg) brightness(112%) contrast(90%);
    }
    to {
        opacity: 0.2;
        transform: scale(1) translate(0,0) rotate(0);
        filter: invert(99%) sepia(5%) saturate(29%) hue-rotate(273deg) brightness(112%) contrast(90%);
    }
}
@keyframes slide2 {
    from {
        opacity: 0;
        transform: scale(1.1) translate(50%,-50%) rotate(90deg);
        filter: invert(11%) sepia(84%) saturate(5265%) hue-rotate(223deg) brightness(97%) contrast(95%);
    }
    to {
        opacity: 0.2;
        transform: scale(1) translate(0,0) rotate(0);
        filter: invert(11%) sepia(84%) saturate(5265%) hue-rotate(223deg) brightness(97%) contrast(95%);
    }
}
@keyframes slide3 {
    from {
        opacity: 0;
        transform: scale(1.1) translate(-50%,-50%) rotate(-90deg);
    }
    to {
        opacity: 1;
        transform: scale(1) translate(0,0) rotate(0);
    }
}

@keyframes appear {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }
    70% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}