section.just-text {

    h2 {
        margin: 0 0 24px;
        font-size: 60px;
        font-weight: bold;
        line-height: 48px;

    }

    p {

        strong {
            font-weight: 800;
        }

        &.with-image {

            display: flex;
            align-items: center;
            gap: 24px;

            span {
                flex: 1;
            }

        }
    
    }

}

@media( max-width: 767px ){

    section.just-text {

        h2 {
            font-size: 40px;
            line-height: 50px;
        }

        p.with-image {
            flex-direction: column;
        }

    }

}
