section.m-de-moringa {

	h2 {
		margin-bottom: 24px;
		font-size: 60px;
		font-weight: 800;
		line-height: 60px;
	}

	em {

		font-size: 40px;
		font-weight: bold;
		line-height: 60px;

		&:not(:first-of-type){
			margin-top: 24px;
		}
	
	}

	strong {
		display: block;
		margin-top: 24px;
		font-size: 80px;
		font-weight: 800;
		line-height: 90px;
		color: transparent;
		-webkit-text-stroke: 2px #f2f2f2;
		text-shadow: none;
	}

	svg {
		width: 96px;
		height: auto;
		margin-bottom: 24px;
	}

	path {

		&.dot {
			fill: #4CE0D5;
		}

		&.letter {
			fill: #FFFFFF;
		}
	}

	&.small-title h2 {
		font-size: 40px;
		line-height: 60px;
	}

}

@media( max-width: 1279px ){
	
	section.m-de-moringa {

		em {
			font-size: 20px;
			line-height: 30px;
			font-weight: 300;
		}
		
		svg {
			width: 63px;
			margin-bottom: 16px;
		}
	
	}

}

@media( min-width: 1024px ) AND ( max-height: 800px ){

    section.m-de-moringa em {
    	font-size: 34px;
    	line-height: 48px;
    }

}
@media( max-width: 767px ){
	
	section.m-de-moringa {

		h2 {
			margin-bottom: 16px;
			font-weight: bold;
		}

		strong {
			font-size: 40px;
			line-height: 40px;
		}

		em:not(:first-of-type) {
		 	margin-top: 16px;
		}

		&.small-title h2 {
			font-size: 20px;
			line-height: 30px;
		}

	}

}