a.button,
.arrow-left,
.arrow-right {

	display: table;
	font-size: 24px;
	font-weight: normal;
	color: #F2F2F2;
	text-decoration: none;

	span {
		display: inline-flex;
		align-items: center;
		transition: all .5s ease-in-out;
	}

	svg {
		
		margin-left: 16px;
		
		path {
			transition: all .5s ease-in-out;
		}
	
	}

}
a.button {

	line-height: 24px;

	span {

		height: 64px;
		padding: 4px 30px 0;
		line-height: 56px;
		border-radius: 32px;
		border: 2px solid #F2F2F2;

		svg{
			margin-top: -2px;
		}

	}

	&:hover {

		color: #070606;

		svg path {
			stroke: #070606;
		}

		span {
			background-color: #4CE0D5;
			border-color: #4CE0D5;
		}

	}

	&.color--2 {

		color: #063FB9;

		span {
			height: 56px;
			padding: 5px 32px 0;
			line-height: 47px;
			border-color: #063FB9;
		}

		svg path {
			stroke: #063FB9;
		}

		&:hover {

			color: #FAFAFA;

			svg path {
				stroke: #FAFAFA;
			}

			span {
				background-color: #063FB9;
			}

		}

	}

}

.arrow-left,
.arrow-right {
	svg {
		transform: translateY(-3px);
	}
}

.arrow-left {

	span {
		flex-direction: row-reverse;
	}

	svg {
		margin: 0 16px 0 0;
		transform: scaleX(-1) translateY(-3px);
	}

}

.arrow-right:hover {

	color: #4CE0D5;
	
	svg path {
		stroke: #4CE0D5;
	}

}

@media( max-width: 767px ){

	a.button, 
	.arrow-left, 
	.arrow-right {
		font-size: 20px;
	}
	.arrow-left, 
	.arrow-right {
		svg {
			transform: translateY(-2px);
			width: 24px;
			height: 24px;
		}
	}
	.arrow-left svg {
		transform: translateY(-2px) scaleX(-1);
	}

}
