.box-modal-video {

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: rgba(82, 81, 81, 0.6);
    opacity: 0;
    transform: translateX(-200vw);
    transition: 
        opacity .6s ease-in-out,
        transform 1ms ease .6s
    ;

    .video {

        &-sizer {

            position: relative;
            width: 100%;
            max-width: 1156px;
            padding: 0 42px;

            // button {
            //     position: absolute;
            //     top: 0;
            //     right: 40px;
            //     width: 48px;
            //     height: 48px;
            //     overflow: hidden;
            //     text-indent: -9999vw;
            //     border-radius: 50%;
            //     background: rgba( 255, 255, 255, 0.3 ) url('../images/video-close.svg') no-repeat center center;
            // }
        
        }

        &-wrapper {
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            padding-top: 56.25%;
        }

    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.opened {
        transition: opacity .6s ease-in-out;
        transform: translateX(0);
        opacity: 1;
    }

}

@media( max-width: 768px ){

    .box-modal-video {

        // align-items: flex-end;
        // padding-bottom: 48px;

        .video-sizer {

            padding-left: 16px;
            padding-right: 16px;

            button {
                top: initial;
                bottom: calc( 100% + 12px );
                right: 16px;
            }

        }
    
    }

}

@media( max-width: 480px ){

    .pnbox-modal-video {

        padding-bottom: 16px;

        // .video-sizer button {
        //     width: 30px;
        //     height: 30px;
        //     background-size: 12px 12px;
        // }

    }

}
