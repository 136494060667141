body {

    > header {

        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 64px;
        background: rgba( 242, 242, 242, 1 );
        transition: background .8s ease-in-out;
        z-index: 999;

        > span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1280px;
            height: 100%;
            padding: 0 55px;
            margin: 0 auto;
        }

        .menu-toggle {
            display: none;
        }

        .logo-moringa {

            display: flex;
            align-items: center;
            text-decoration: none;

            svg {
                margin-right: 5px;
            }

            strong {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: #070606;
                transform: translateY(4px);
            }
        
        }

        nav {

            display: flex;
            align-items: center;
            gap: 32px;

            a {

                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: #3A3A3A;
                text-decoration: none;
                text-transform: uppercase;
                transition: all .4s ease-in-out;

                &.active {
                    font-weight: 700;
                    color: #063FB9;
                }
            
            }
        
        }

    }

    &.page-a-moringa > header {

        background: rgba( 242, 242, 242, 0 );

        &.scrolled {
            background: rgba( 242, 242, 242, 1 );
        }
    
    }

}

@media( max-width: 1140px ){

    body {

        > header {

            > span {
                padding: 0 24px;
            }

            .menu-toggle {
                display: block;
            }

            nav {

                position: fixed;
                top: 64px;
                left: 0;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100vw;
                height: calc( 100vh - 64px );
                gap: 0;
                background: #F2F2F2;
                opacity: 0;
                transform: translateX(-200vw);
                transition: 
                    opacity .8s ease-in-out,
                    transform 1ms ease .8s
                ;

                a {

                    width: 100%;
                    text-align: center;
                    padding: 22px 24px 16px;
                    
                    &:not(:last-child){
                        border-bottom: 1px solid #E7E7E7;
                    }
                
                }

            }

        }

        &.menu-opened > header {
            
            background: rgba( 242, 242, 242, 1 ) !important;
            
            nav {
                transition: opacity .8s ease-in-out;
                opacity: 1;
                transform: translateX(0);
            }

        }

    }

}