section.projetos {

	> span {
		padding-right: 135px;
	}

	h2 {

	    display: flex;
	    
	    span {
	        
	        text-transform: uppercase;
	        
	        &:first-child {

	            font-weight: 900;
	            font-size: 80px;
	            line-height: 90px;
	            letter-spacing: 0.1em;
	            color: #D9D9D9;
	            -webkit-text-stroke: 2px #F2F2F2;;
	            text-stroke: 2px #F2F2F2;;
	        }
	        
	        &:last-child {
	            margin-top: 44px;
	            font-size: 60px;
	            font-weight: 400;
	            line-height: 70px;
	            color: rgba( 242, 242, 242, .5 );
	        }

	    }

	}

	.image-wrapper	{

		display: block;
		position: relative;
		padding: 34px 40px 0 0;

		picture {
			display: block;
			position: relative;
			width: 100%;
			height: 0;
			padding-top: 77.0491803279%;
			border-radius: 10px;
			overflow: hidden;
			background: darken( #4ce0d5, 26% );
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border: 0;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			width: 94.9230769231%;
			height: 95.2380952381%;
			border-radius: 10px;
			background: #4CE0D5;
			z-index: 1;
		}

	}

	.arrows-nav {

		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		padding: 0;
		margin: 0;
		gap: 24px;

		button {

			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			border: 3px solid #4CE0D5;
			background: transparent;
			border-radius: 51%;
			cursor: pointer;
			z-index: 10;
			transition: all .5s ease-in-out;

			&,
			svg path {
				transition: all .5s ease-in-out;
			}
			
			&.next svg {
				transform: scale(-1);
			}

			&:not(.slick-disabled):hover {

				color: #070606;
				background-color: #4CE0D5;
				border-color: #4CE0D5;

				svg path {
					fill: #070606;
				}
			
			}

			&.slick-disabled {
				opacity: .6;
				cursor: default;
			}

		}
	
	}
	
	.slider-wrapper {

		position: relative;
		margin-top: 40px;
		
		.slider {
			padding-bottom: 61px;
		}
	
	}

	.projeto {

		display: grid !important;
		grid-template-areas:
		    'top right'
		    'bottom right';
		grid-template-columns: 40.5% 59.5%;
		grid-auto-rows: 1fr min-content;

		header,
		footer {
			padding-right: 48px;
		}
		header {
			grid-area: top;
		}
		footer {
			grid-area: bottom;
			padding-bottom: 58px;
		}

		em {
			display: table;
			margin: 24px 0 0;
			font-size: 16px;
			font-weight: 200;
			text-transform: uppercase;
		}

		p {
			margin: 0;
			color: #FFF;
			font-size: 20px;
			font-weight: 200;
			line-height: 30px;
		}

		a.arrow-right {
			margin-top: 32px;
		}

		> span {
			flex: 1;
		}

		.image-wrapper {
			width: 100%;
			grid-area: right;
		}

		&.asn em {
			position: relative;
			left: 63.5%;
			margin: -8% 0 0;
		}

		&.psj em {
			position: relative;
			left: 20.8%;
			margin: -11% 0 0;
		}
	
	}

}


@media( min-width: 1024px ) AND ( max-height: 800px ){
	
	section.projetos {

		h2 span {
			&:first-child {
				font-size: 70px;
				line-height: 60px;
			}
			&:last-child {
				margin-top: 30px;
				font-size: 40px;
				line-height: 50px;
			}
		}

		.projeto {

			header {
			
				padding-top: 50px;

				picture {
					width: 220px;
				}
			
			}

			p {
				font-size: 18px;
				line-height: 26px;
			}
		
			.image-wrapper {
		  		width: 82%;
		  		margin-left: auto;
		  	}

		  	em {
				display: table;
				margin: 10px 0 0;
			}

			&.asn em {
				left: 35.5%;
				margin: -4% 0 0;
			}
			
			&.psj em {
				left: 11.8%;
				margin: -6% 0 0;
			}

		}

	  	.arrows-nav button {

	  		width: 60px;
	  		height: 60px;

	  		svg {
	  			width: 30px;
	  			height: auto;
	  		}
	  	
	  	}

	  	.slider-wrapper {

	  		margin-top: -40px;

	  		.slider {
	  			padding-bottom: 32px;
	  		}
	  	
	  	}
	
	}

}

@media( max-width: 1279px ){

	section.projetos {

		> span {
 			padding-right: 75px;
		}

		h2 span {

			&:first-child {
				font-size: 60px;
				line-height: 70px;
			}

			&:last-child {
				margin-top: 37px;
				font-size: 40px;
				line-height: 50px; 
			}

		}

		picture img {
			width: auto;
			height: 100px;
			margin: 0 auto;
		}

		.slider-wrapper {

			margin-top: 32px;
			
			.slider {
			  padding-bottom: 18px;
			}
		
		}

		.projeto {
			
			header, 
			footer {
			  	padding-right: 32px;
			}

			em {
				margin: 24px auto 0;
			}

			p {
				margin: 32px 0 0;
				font-size: 20px;
				line-height: 30px;
			}

			&.asn em {
				left: 63%;
				margin: -7% 1px 0;
			}

			&.psj em {
				left: 32%;
  				max-width: 180px;
				margin: -6% 0 0;
			}

		}

		.arrows-nav button {

			width: 42px;
			height: 42px;
			border: 2px solid #4CE0D5;

			&,
			svg path {
				transition: all .5s ease-in-out;
			}

			svg {
				width: 24px;
				height: auto;
			}
			
			&.next svg {
				transform: scale(-1);
			}

			&:not(.slick-disabled):hover {

				color: #070606;
				background-color: #4CE0D5;
				border-color: #4CE0D5;

				svg path {
					fill: #070606;
				}
			
			}

			&.slick-disabled {
				opacity: .6;
				cursor: default;
			}

		}

	}

}

@media( max-width: 768px ){

	section.projetos {

		padding-top: 60px;

		> span {
			padding-right: 24px;
		}

		h2 {
			justify-content: center;
		}

		.slider-wrapper {

			margin-top: 8px;

			.slider {
		  		padding-bottom: 0;
		  	}

		}

		.image-wrapper picture {
			padding-top: 55.5555555556%;
		}

		.projeto {

			display: flex !important;
			flex-direction: column;
			align-items: center;

			header,
			footer {
				padding-right: 0;
			}
			header em {
				line-height: 24px;
			}

			&.asn header img {
				height: 106px;
			}

			&.psj header {
					
				img {
					height: 78px;
				}

				em {
					left: 22%;
					margin: -11% 0 0;
				}

			}
			
			&.outros-50 header {
					
				img {
					height: 78px;
					width: auto;
				}

				em {
					margin-top: 14px;
				}

			}
			
			&.rotulagem header {

				em {
				 	margin-top: 18px;
				}
			
			}

			.image-wrapper {
				width: 100%;
				padding: 10px 10px 0 0;
				margin-top: 16px;
			}

			p,
			a.arrow-right {
			  margin-top: 16px;
			}

		}
	
	}

}	