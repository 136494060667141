body.single-projetos > main > section.strong-bottom {

	p {
		font-size: 40px;
		line-height: 60px;
		font-weight: 600;
	}
	em {
		display: block;
		font-size: 60px;
		font-weight: 700;
		line-height: 70px;
	}
	img {
		border-radius: 10px;
	}

	> span {

		display: flex;
		gap: 27px;
		align-items: center;

		> span {

			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 32px;

			> div {

				display: flex;
				align-items: center;
				gap: 27px;

				> span,
				> em {
					flex: 1;
				}

				&:nth-child(2) picture {
					margin-top: -26px;
				}

			}

		}
	
	}

	&.image-left-all > span {
		flex-direction: row-reverse;
	}

	&.text-size {

		&--2,
		&--3 {
			p {
				font-size: 32px;
				font-weight: 400;
				line-height: 48px;

			}
		}
		&--2 {
			em {
				font-size: 40px;
				line-height: 60px;
			}
		}

		&--3 {
			p {
				font-weight: 200;
			}
			em {
				font-size: 32px;
				line-height: 48px;
			}
		}

	}

}

@media( max-width: 1279px ){
	body.single-projetos > main > section.strong-bottom {

		 > span > span {
		 
		 	gap: 16px; 

		 	> div {

			 	flex-direction: column;
			 	gap: 16px;

			 	&:nth-child(2) picture {
				  	margin-top: 0;
				}
			
			}
		 
		 }
		
		&.text-size {

			&--2, 
			&--3 {
			
				p {
					font-size: 20px;
					line-height: 30px;
				}
			
			}

			&--3 em {
				font-weight: 500;
				font-size: 20px;
				line-height: 28px;
			}
		
		}

	}

}

@media( max-width: 1023px ){

	body.single-projetos > main > section.strong-bottom {

		em {
			font-size: 32px;
			font-weight: bold;
			line-height: 42px;
		}

		&,
		&.text-size--2, 
		&.text-size--3 {

			> span > span {
				gap: 16px;
			}

			p {
				font-size: 20px;
				font-weight: 200;
				line-height: 30px;
			}

		}

		&.text-size--2, 
		&.text-size--3 {
			em {
				font-size: 20px;
				line-height: 30px;
				font-weight: normal;
			}
		}

	}

}
