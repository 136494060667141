.menu-toggle {

	display: block;
	position: relative;
	width: 32px;
	height: 32px;
	padding: 0;
	border-radius: 0;
	border: 0;
	cursor: pointer;
	z-index: 50;
	transition: all 400ms;
	background: none;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		transition: all 400ms;
	}

	> span {
		
		display: block;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY( -50% );
		width: 30px;
		height: 18px;

		span {

			display: block;
			position: absolute;
			width: 100%;
			height: 3px;
			opacity: 1;
			left: 0%;
			transform: rotate(0deg);
			background: #063FB9;

			&:nth-child(1),
			&:nth-child(3){
				transition: 
					transform .3s ease, 
					left .3s ease,
					top .3s ease .3s,
					background-color 300ms
				;
			}
			&:nth-child(1) {
				top: 0px;
			}
			&:nth-child(2){
				top: 7px;
				transition: width .4s ease, opacity .4s ease, background-color .3s;
			}
			&:nth-child(3) {
				top: 14px;
			}
		
		}

	}

}

.menu-opened .menu-toggle {
	
	> span {

		margin-top: -2px;

		span {

			height: 3.5px;

			&:nth-child(2) {
				width: 0;
				opacity: 0;
			}
			&:nth-child(1),
			&:nth-child(3){
				top: 50%;
				left: 0;
				transition: 
					top .3s ease, 
					transform .3s ease .3s, 
					left .3s ease .3s,
					background-color .3s
				;
			}
			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
			}

		}

	}

}
