@media all and( -ms-high-contrast:none ){
   .piki-sanfona.horizontal .sanfona-header {
      transform: rotate(-90deg);
   }     
}

@media screen and( -ms-high-contrast: active ), screen and( -ms-high-contrast: none ){  
   .piki-sanfona.horizontal .sanfona-header {
      transform: rotate(-90deg);
      right: -220px;
      bottom: 100px;
   }  	
}

@media screen and ( -ms-high-contrast: none ) {
   .piki-sanfona.horizontal .sanfona-header {
      transform: rotate(-90deg);
   }     
}


@supports( -ms-ime-align:auto ){
   .piki-sanfona.horizontal .sanfona-header {
      transform: rotate(-90deg);
      right: -167px;
   }     
}