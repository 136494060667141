body > main > section.site-footer {

	> span {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.brand {

		display: flex;
		flex-direction: column;

		em {
			margin-top: -56px;
			color: #F2F2F2;
			font-size: 18.65px;
			font-style: normal;
			font-weight: 400;
			line-height: 27.975px;
		}

		svg.mobile {
			display: none;
		}
	
	}

	.info p,
	.networks em {		
		font-size: 16px;
		font-weight: 300;
	}

	.info {
		
		color: #F2F2F2;

		p {

			margin: 0;
			font-size: 20px;
			font-weight: 300;

			&:not(:first-child){
				margin-top: 50px;
			}
		
		}

		p a {

			display: block;
			margin-top: 8px;
			font-size: 26px;
			font-weight: 700;
			line-height: 108.5%;
			text-decoration: none;
			color: inherit;
			transition: all .5s ease-in-out;

			span {
				font-size: 20px;
			}

			&:hover {
				color: #FF00BF;
			}

		}

	}

	.networks {

		margin-top: 24px;

		em {
			line-height: 42px;
		}

		nav {
			display: flex;
			align-items: center;
			margin-top: 8px;
			gap: 24px;
		}

		a {

			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 51%;
			border: 2px solid #F2F2F2;
			transition: all .6s ease-in-out;

			svg path {
				transition: all .5s ease-in-out;
			}

			&:hover {

				border-color: #FF00BF;
			
				svg path {
					fill: #FF00BF;
				}
			
			}
		
		}
	
	}

	.menu {

		flex-basis: 100%;
		margin-top: 24px;

		nav {
			display: flex;
			gap: 24px;
		}

		em {
			display: block;
			margin-bottom: 8px;
			color: #FFF;
			font-size: 16px;
			font-style: normal;
			text-transform: uppercase;
		}

		a {

			color: #F2F2F2;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			text-transform: uppercase;
			text-decoration: none;
			transition: all .5s ease-in-out;

			&.active {
				color: #4CE0D5;
				font-weight: 800;
			}

			&:not(.active):hover{
				color: #FF00BF;
			}
		
		}
	
	}
    
}

@media( min-width: 1024px ) AND ( max-height: 800px ){

	body > main > section.site-footer {

		> span {
	  		align-items: flex-end;
	  	}

	  	.brand {

	  		width: 100%;
	  		max-width: 484px;
	  		padding-right: 40px;

	  		svg {
	  			width: 100%;
	  			height: auto;
	  		}
	  	
	  	}

		.info p:not(:first-child) {
		  margin-top: 30px;
		}
	
	}

}

@media( min-width: 769px ){

	body > main > section.site-footer .info p a {

		&[href^="mailto:"]{

			display: table;
			position: relative;

			&:after,
			&:before {
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				font-size: 22px;
				font-weight: 800;
				line-height: 108.5%;
				color: #FF00BF;
				opacity: 0;
				white-space: nowrap;
				transition: all .6s ease-in-out;
			}
			&:before {
				content: 'Copiar para área de transferência...';
			}
			&:after {
				content: 'E-mail copiado com sucesso!';
			}
			&:hover:before {
				opacity: 1;
			}
			&.copied {
				&:before {
					opacity: 0;
				}
				&:after {
					opacity: 1;
				}
			}

		}

	}
}

@media( max-width: 768px ){

	body > main > section.site-footer {

		min-height: 0;
		padding-bottom: 42px;

		> span {
			gap: 0;
		}

		.menu nav {
			display: none;
		}
		
		.brand {

			width: 100%;
			max-width: 254px;
			padding-left: 16px;
			padding-right: 16px;
			margin: 0 auto;
			order: 1;

			br {
				display: none;
			}
		
			em {
				margin-top: 8px;
				font-size: 12px;
				line-height: 14px;
			}

			svg {
		
				&.desktop {
					display: none;
				}
				&.mobile {
					width: 200px;
					height: auto;
					display: initial;
				}
		
			}
		
		}

		.menu {

			order: 2;
			text-align: center;

			em {
				display: none;
			}

			nav {

				flex-direction: column;
				gap: 0;

				a {
					height: 32px;
					line-height: 32px;
					font-size: 16px;
				}
			
			}
		
		}
	
		.info {

			order: 3;
			width: 100%;
			margin-top: 32px;
			text-align: center;

			p {

				font-size: 14px;
			
				em, 
				a {
					display: block;
					width: 100%;
				}

				em {
					margin-top: 0;
					font-size: 14px;
					line-height: 17px;
					font-weight: 200;
				}
				
				a {

					margin-top: 8px;
					font-size: 18px;
					line-height: 18px;

					> span {
						
						display: block;
						font-size: 12px;
						line-height: 14px;

						span {
							display: none;
						}

					}
				
				}	

				&:not(:first-child){
					margin-top: 24px;
				}

			}

		}

		.networks {

			margin-top: 78px;

			em {
				font-size: 14px;
				line-height: 18px;
			}

			nav {
				justify-content: center;
			}

			a {

				width: 32px;
				height: 32px;

				svg {
					width: 16px;
					height: auto;
				}

			}

		}

	}

}
