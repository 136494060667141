*, *:before, *:after {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}
body {
    margin: 0;
    font-family: 'Nexa', sans-serif;
    color: #F2F2F2;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
}
body > main {
    //will-change : 'transform';
}
html,
body {
    scroll-behavior: smooth;
}

body.mobile-nav > main > section {

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding-top: 24px;
    padding-bottom: 24px;

    &:first-of-type {
        padding-top: 88px;
    }


}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

em {
    display: block;
    font-style: normal;
}

picture {
    &,
    img {
        display: block;
        line-height: 0;
    }
}
img {
    max-width: 100%;
    height: auto;
}

a.button {

}

button {

}

i {

    display: block;
    width: 30px;
    height: 30px;
    background: no-repeat center center;
    background-size: contain;

    &.coracao {
        background-image: url('../images/icon-coracao.svg');
    }

}

blockquote {

    margin: 0;
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 48px;

    &.with-images {
    
        position: relative;
        display: table;
        z-index: 10;
        font-size: 50px;
        font-weight: bold;
        line-height: 70px;
        font-style: normal;

        &:before,
        > span:after {
            content: '“';
            display: block;
            position: absolute;
            font-size: 150px;
            line-height: 150px;
        }

        &:before {
            top: -27px;
            left: -63px;
            z-index: 1;
        }
        > span:after {
            bottom: 23px;
            right: -63px;
            transform: rotate(180deg);
            z-index: 1;
        }

        &.text-size--2 {

            font-size: 40px;
            line-height: 50px;

            &:before,
            > span:after {
                font-size: 100px;
                line-height: 100px;
            }

            &:before {
                top: -24px;
                left: -44px;
            }
            > span:after {
                bottom: 27px;
                right: -52px;
            }
        
        }        
    
    }

}

.pseudo {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

body > main {

    > section {

        padding-top: 64px;

        > span {

            @extend .center;
            position: relative;
            z-index: 10;

            * {
                position: relative;
                z-index: 20;
            }
        
        }

        &:first-of-type {
            position: relative;
        }

        .seta {
            position: absolute;
            left: calc( 50% - 23px );
            bottom: 56px;
            width: 46px;
            height: 51px;
            z-index: 999;
        }

        .tags {

            position: relative;
            display: flex;
            flex-wrap: wrap;
            padding: 16px 0 0;
            margin: 16px 0 0;
            gap: 16px;
            list-style: none;

            li {
                line-height: 16px;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0px;
                left: 0;
                width: 130px;
                height: 1px;
                background: #F2F2F2;
            }

        }

        &.m-left {

            > span {

                display: flex;
                align-items: flex-start;

                > div {
                    flex: 1;
                }

                > svg {

                    width: 96px;
                    min-width: 96px;
                    height: auto;
                    margin: 12px 34px 0 0;

                    path.dot {
                        fill: #4CE0D5;
                    }
                    path.letter {
                        fill: #FFFFFF;
                    }
                
                }

            }

            &.style {

                &--1 {

                    em {

                        flex: 1;
                        font-size: 60px;
                        font-weight: 300;
                        line-height: 70px;

                        &:not(:first-of-type){
                            margin-top: 24px;
                        }
                    
                    }

                }

            }

            &.style {

                &--2 {

                    h2 {
                        margin-bottom: 24px;
                        font-size: 48px;
                        font-weight: bold;
                        line-height: 56px;
                    }

                    p {

                        margin: 0;
                        font-size: 36px;
                        line-height: 52px;
                        font-weight: 300;

                        &:not(:first-of-type){
                            margin-top: 16px;
                        }

                    }

                }

            }

        }

        &.just-text {
            h2 {
                margin: 0 0 24px;
                font-size: 60px;
                font-weight: bold;
                line-height: 48px;

            }
        }

        &.no-image-radius {
            img,
            picture {
                border-radius: 0 !important;
            }
        }

        &:before,
        &:after {
            @extend .pseudo;
            position: absolute;
            z-index: 2;
        }
        &:before {
            background-image: url('../images/firula-01.svg');
            opacity: 0.2;
        }
        &:after {
            background-image: url('../images/firula-02.svg');
        }

    }

}
.center,
.center--big {

    display: block;
    width: 100%;
    max-width: 1280px;
    padding: 0 127px 0 55px;
    margin: 0 auto;

}
.center--big {
    max-width: 1050px;
}

.graph {

    position: absolute;
    width: 100%;
    
    span {

        display: block;
        position: relative;
        width: 100%;
        height: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: no-repeat center center;
            background-size: contain;
        }            
    
    }

}

.video-wrapper {

    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-top: 56.4646464646%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.video-container {

    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;

    iframe,
    img,
    svg { 
        position: absolute;
        transition: opacity .4s ease-in-out;
    }
    iframe,
    img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    img,
    svg {
        cursor: pointer;
    }
    iframe {
        z-index: 1;
        opacity: 0;
        background: #000;
    }
    img {
        z-index: 2;
    }
    svg {

        top: 50%;
        left: 50%;
        width: 68px;
        height: auto;
        transform: translate(-50%,-50%);
        z-index: 3;

        path:first-of-type{
            fill: #333;
            transition: fill .4s ease-in-out;
        }
    
    }

    &:hover svg path:first-of-type {
        fill: red;
    }

    &.ready {
        img,
        svg {
            opacity: 0;
        }
        iframe {
            opacity: 1;
            z-index: 5;
        }
    }

}

@media( max-width: 1330px ){

    blockquote.with-images {

        margin-left: 40px;
        margin-right: 40px;
        font-size: 38px;
        line-height: 58px;

        &:before,
        & > span:after {
            font-size: 100px;
            line-height: 100px;
        }
        &:before {
            top: -15px;
            left: -44px;
        }
        & > span:after {
            bottom: 21px;
            right: -48px;
        }

    }

}

@media( max-width: 1279px ){

    body > main > section.m-left.style--1 em {
        font-size: 50px;
        line-height: 60px;
    }

}

@media( max-width: 1024px ){

    body > main > section.m-left.style--1 em {
        font-size: 40px;
        line-height: 50px;
    }

}

@media( max-width: 768px ){

    .center, 
    body > main > section > span {
        display: block;
        padding: 0 75px 0 24px;
    }

    body > main > section {

        padding-top: 0;

        .tags li {
            font-size: 12px;
            line-height: 18px;
        }

        &.m-left {

            > span {

                flex-direction: column;

                > svg {
                    width: 64px;
                    min-width: 64px;
                    height: auto;
                    margin: 0 0 16px;
                }

            }

            &.style--1 {
            
                em {
                    font-size: 20px;
                    line-height: 30px;
                }
            
            }

            &.style--2 {

                h2 {
                    font-size: 32px;
                    line-height: 38px;
                }
            
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            
            }

        }

    }

}

@media( max-width: 767px ){
    .center, 
    body > main > section > span {
        padding: 0 64px 0 18px;
    }

    body.mobile-nav > main > section > span {  
        padding: 0 18px 0 18px;
    }


}

@media( max-width: 640px ){

    blockquote.with-images {

        margin-left: 0;
        margin-right: 0;
        font-weight: normal;

        &,
        &:before,
        & > span:after {
            font-size: 22px;
            line-height: 33px;
        }
        &:before,
        & > span:after {
            display: inline;
            position: initial;
            top: initial;
            right: initial;
            bottom: initial;
            left: initial;
        }

    }

}

@media( max-width: 480px ){

    body > main > section .seta {
        bottom: 20px;
    }

}

@media( min-width: 1024px ) AND ( max-height: 800px ){

    .center, 
    body > main > section > span {
        padding-right: 80px;
    }



    body > main > section {

        .seta {
            bottom: 20px;
        }

        &.m-left.style {

            &--1 em {
                font-size: 50px !important;
                line-height: 60px !important;;
            }

            &--2 p {
                font-size: 30px;
                line-height: 45px;
            }

        }

    }

}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clear:before,
.clear:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden; 
}
.clear:after{ 
    clear: both;  
}
.clear { 
    zoom: 1; 
}