.menu-nav-side,
.slick-dots {
    
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    transition: all .6s ease-in-out;

    li {

        height: 30px;

        a,
        button {

            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0;
            border: 0;
            background: transparent;
            color: transparent;

            &:before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #D9D9D9;
                transition: all .4s;
            }

            strong {
                position: absolute;
                right: calc( 100% - 8px );
                top: 50%;
                display: block;
                height: 33px;
                padding: 1px 8px 0;
                line-height: 32px;
                background: #FEFEFE;
                border-radius: 4px;
                transform: translate( -200vw, -50% );
                font-size: 14px;
                text-align: right;
                font-weight: 400;
                color: #121212;
                white-space: nowrap;
                opacity: 0;
                transition:
                    background-color .4s ease-in-out,
                    color .4s ease-in-out,
                    opacity .4s ease-in-out,
                    transform 0 ease .4s
                ;
            }

            &:hover strong {
                transition: 
                    background-color .4s ease-in-out,
                    color .4s ease-in-out,
                    opacity .4s ease-in-out
                ;
                opacity: 1;
                transform: translate( 0, -50% );
            }
        
        }

        &.slick-active button:before,
        a.active:before {
            width: 16px;
            height: 16px;
            background: #FEFEFE;
        }

    }

    &.color-black li {

        a,
        button {

            strong {
                color: #FEFEFE;
                background-color: #070606;
            }

            &:before {
                background-color: #070606;
            }

        }
    
    }

}

.menu-nav-side {
    position: fixed;
    top: calc( 50% + 32px );
    left: calc( 50% + 570px );
    display: flex;
    flex-direction: column;
    width: 48px;
    transform: translateY(-50%);
}

.slick-dots {

    justify-content: center;
    margin-top: 8px;

    li {
        width: 32px;
        height: 32px;
    }

    button {

        content: ' ';
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-indent: -9999vw;

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }

    }

}

@media( max-width: 1185px ){
    .menu-nav-side {
        left: initial;
        right: 11px;
    }
}

@media( max-width: 767px ){
    .menu-nav-side {
        right: 6px;
        top: 135px;
        transform: none;
    }
}