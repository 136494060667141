section.text-image-side {

	> span {

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 24px;

		div {

			flex: 1;

			blockquote {
				font-size: 48px;
				margin-top: 40px;
				font-weight: bold;
				line-height: 58px;
				font-style: normal;
			}
		
		}

		> blockquote {
			margin: 56px 0 0;
			flex-basis: 100%;
		}

	}

	h2 {
		margin: 27px 0 0;
		font-size: 40px;
		font-weight: 800;
		line-height: 42px;
	}

	p {

		display: flex;
		align-items: flex-end;
		gap: 27px;
		
		span {
			flex: 1;
		}
	
	}

	img {
		border-radius: 10px;
	}

	&.text-size {

		&--02 {

			p {
				font-size: 24px;
				line-height: 36px;
			}

			blockquote {
				font-size: 24px;
				font-style: italic;
				line-height: 36px;
				font-weight: 300;
			}
		
		}

	}

	&.space {

		&--28 > span {
			gap: 28px;
		}

		&--46 > span {
			gap: 46px;
		}

		&--100 > span {
			gap: 100px;
		}

	}

	&.image-left > span {
		flex-direction: row-reverse;
	}

	&.image-bottom > span {

		flex-direction: column;
		justify-content: center;
		gap: 35px;
	
	}

	&.valign-top > span {
		align-items: flex-start;
	}

}

@media( max-width: 1023px ){
	
	section.text-image-side {

		> span {

			flex-direction: column-reverse;
			align-items: flex-start;
			gap: 24px !important;
			
			div {
			 	flex: initial;
			}

			svg {
				width: 95px;
				height: auto;
			}

			> blockquote {
				flex-basis: initial;
				margin-top: 0;
				font-size: 20px;
				line-height: 30px;
				font-weight: 200;
			}

		}

		&.image-left > span {
			flex-direction: column;
		}

		&.quote-bottom > span {
		
			flex-direction: column;
			gap: 16px !important;

			h2 {
				order: 1;
			}
			picture {
				order: 2;
			}
			> div {
				order: 3;
			}
			blockquote {
				order: 4;
			}
		
		}
	
		&.text-size {

			&--02 {

				p {
					font-size: 20px;
					line-height: 30px;
				}

				blockquote {
					font-size: 22px;
					line-height: 33px;
				}
			
			}

		}

	}

}

@media( max-width: 768px ){

	section.text-image-side {

		picture  {
			display: table;
			margin: 0 auto;
		}

		> span div blockquote {
			font-size: 20px;
			line-height: 30px;
		}
	
	}

}

@media( max-width: 767px ){

	section.text-image-side p {
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}

}
